<template>
  <loading v-if="loading" />
  <div v-else>
    <b-table
      :data="sortedContracts"
      :mobile-cards="true"
      :default-sort="['status', 'desc']"
      hoverable
      striped
      class="has-vertical-align-middle"
      @click="onClick"
    >
      <template slot="empty">
        <no-results
          icon="box-open"
          title="No subscriptions"
          message="There are no subscriptions to show."
        />
      </template>

      <template slot-scope="props">
        <b-table-column label="Status" field="status" width="1">
          <status-label :status="`${props.row.status}`" />
        </b-table-column>

        <b-table-column label="Site" field="site.fqdn" sortable>
          <p>
            <router-link
              :to="{ path: `/sites/${props.row.site.ref.id}` }"
              class="has-text-dark"
            >
              <u>{{ props.row.site.fqdn }}</u>
            </router-link>
            – {{ props.row.product.name }}
          </p>
          <p
            v-if="$_.has(props.row.product, 'term')"
            :class="{ 'has-text-danger': props.row.status == 2 }"
          >
            <span v-if="props.row.status == 2">
              Payment due
              {{ $moment(props.row.dateNextDue.toDate()).from() }}
            </span>

            <span v-else-if="props.row.status == statuses.ACTIVE">
              Renews every
              {{
                props.row.product.term.length > statuses.ACTIVE
                  ? `${props.row.product.term.length} months`
                  : `month`
              }}
            </span>

            <span v-else-if="props.row.status == statuses.CANCELLED"
              >Expires
              {{ $moment(props.row.dateNextDue.toDate()).from() }}</span
            >

            <span v-else-if="props.row.status == statuses.LAPSED"
              >Lapsed {{ $moment(props.row.dateNextDue.toDate()).from() }}</span
            >
          </p>
        </b-table-column>

        <b-table-column
          v-if="$_.has(props.row, 'dateNextDue')"
          :class="{ 'has-text-danger': props.row.status == statuses.OVERDUE }"
          label="Next due"
          field="dateNextDue"
          width="150"
          sortable
        >
          <span v-if="props.row.status > statuses.CANCELLED">{{
            $moment(props.row.dateNextDue.toDate()).format("Do MMM, YYYY")
          }}</span>

          <span v-else>–</span>
        </b-table-column>

        <b-table-column
          v-if="adminContext && $_.has(props.row, 'dateCreated')"
          label="Date created"
          field="dateCreated"
          width="150"
          sortable
          >{{ $moment(props.row.dateCreated.toDate()).from() }}</b-table-column
        >

        <b-table-column v-if="!adminContext" width="1" class="has-text-right">
          <contract-action :contract="props.row" />
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { enums as statuses } from "@/data/contract";
export default {
  name: "ContractsTableObserved",
  components: {
    "contract-action": () => import("@shared/contracts/_contractAction")
  },
  props: {
    userId: {
      default: null,
      validator: value => {
        return ["string", "null"].includes(typeof value);
      }
    },
    adminContext: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      processing: [],
      selected: null,
      statuses
    };
  },
  computed: {
    ...mapState({
      contracts: state => state.billing.contracts
    }),
    sortedContracts() {
      return this.$_.orderBy(
        this.contracts,
        ["status", "dateNextDue"],
        ["desc", "asc"]
      );
    }
  },
  created() {
    this.$store
      .dispatch("billing/observeContracts", { userId: this.userId })
      .finally(() => {
        this.loading = false;
      });
  },
  beforeDestroy() {
    this.$store.dispatch("billing/unobserveContracts", {
      userId: this.userId
    });
  },
  methods: {
    onClick(contract) {
      if (this.adminContext) {
        this.$router.push({ path: `/admin/contracts/${contract._id}` });
      }
    }
  }
};
</script>
